import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import BHero from "../../../components/Hero/b-hero";
import {
  tireCenterHero,
  tireCenterSubHero,
  tireCenterWhiteGlove,
  tireCenterLargeFeatureA,
  tireCenterTrusted,
  tireCenterMultiSection,
  tireCenterCTA,
  tireCenterVideo,
  // tireCenterTestimonials,
  tireCenterArticles,
  tireCenterCar,
} from "../../../data/subverticals/automotive/tire-center-data";
import {
  heroSuccess,
  NorthEastAutoSlides,
} from "../../../data/success-stories-data";
// import NoVisualsCustom from "../../../components/NoVisuals/NoVisualsCustom";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import SuccessStories from "../../../components/SuccessStories/SuccessStories";
// import VideoSection from "../../../components/VideoSection/VideoSection";
// import BusinessTypes from "../../../components/BusinessTypes/BusinessTypes";
// import Articles from "../../../components/Articles/Articles";

import heroBg from "../../../images/hero-images/tire-center-hero.png";

const NoVisualsCustom = loadable(() =>
  import("../../../components/NoVisuals/NoVisualsCustom")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);
const VideoSection = loadable(() =>
  import("../../../components/VideoSection/VideoSection")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
const Articles = loadable(() =>
  import("../../../components/Articles/Articles")
);

const whiteGloveImport = "auto-repair-wg.png";

const tireCenter = () => {
  return (
    <Layout>
      <SEO
        title="Tire Shop Point of Sale (POS) Software  | SpotOn"
        description="Provide faster service for your tire shop with our POS. SpotOn offers integrated POS, flexible payment options, and appointment booking software. Get a demo!"
        image={`https://spoton.com/${heroBg}`}
        cannonical="https://wwwspoton.com/automotive/tire-center/"
      />
      <BHero sectionData={tireCenterHero} heroBg="car-sales-hero.png" />
      <NoVisualsCustom sectionData={tireCenterSubHero} complex />
      <WhiteGlove
        sectionData={tireCenterWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={tireCenterLargeFeatureA}
        style={{ marginTop: "128px" }}
      />
      <TrustedNumbers title="By the numbers" numbersArray={tireCenterTrusted} />
      <LargeFeatures
        sectionData={tireCenterMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      <LargeCta sectionData={tireCenterCTA} />
      <SuccessStories sectionData={heroSuccess} slides={NorthEastAutoSlides} />
      <VideoSection sectionData={tireCenterVideo} />
      {/* <TestmonialReviews sectionData={tireCenterTestimonials} /> */}
      <Articles
        sectionData={tireCenterArticles}
        blogFilters={{
          limit: 3,
          include: "tags,authors",
          filter: "tag:auto",
        }}
        cornerStone={false}
        style={{ marginTop: 60 }}
      />
      <BusinessTypes sectionData={tireCenterCar} />
      {/* <Faqs sectionData={tireCenterFaqs} /> */}
    </Layout>
  );
};

export default tireCenter;
