// eslint-disable-next-line import/prefer-default-export
export const tireCenterHero = {
  title: "Tire shop POS software",
  subtext:
    "Service more customers and increase sales with an integrated software and point-of-sale solution customized for your tire center.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/automotive/tire-center/demo",
  },
};
export const tireCenterSubHero = {
  title: "Connect with customers using better tech",
  subtext:
    "Get the tools you need to attract new customers and then keep them coming back as life-long regulars.",
  list: [
    "Attract more customers with a strong online presence",
    "Manage appointments and team schedules",
    "Improve customer retention and grow revenue",
    "Offer easier ways to collect payments",
    "Improve your customer experience",
  ],
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/automotive/tire-center/demo",
  },
};

export const tireCenterWhiteGlove = {
  mainTitle: "Personalized service & support",
  title: "Work with a team that cares about your success",
  content:
    "You didn’t get into the automotive business to deal with tech. Our team will work closely with you to put together a custom toolset that makes life easier for both you and your customers. We’ll train you and your team how to make the most of your new tools. And we’ll be there whenever you need us with local service and 24/7 support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/automotive/tire-center/demo",
  },
};

export const tireCenterLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle: "Built for speed and efficiency",
      blockSubTitle:
        "SpotOn’s reliable cloud-based platform simplifies your appointment-booking and checkout process, while making it easier to stay in touch with customers—creating a better guest experience that will help you grow your business and set you apart from the competition. Equally important, our pricing is fair, so more money stays in your pocket.",
      blockList: [
        "Transparent pricing",
        "Next-day funding",
        "No hidden fees",
        "No long-term commitment",
      ],
      blockImg: "tc-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/tire-center/demo",
      },
    },
  ],
};

export const tireCenterTrusted = [
  {
    title: "30-40%",
    subtext: "Increase in sales with online appointment booking",
  },
  {
    title: "91%",
    subtext:
      "Of Millennials trust online reviews as much as friends and family",
  },
  {
    title: "88%",
    subtext: "Of people search online before visiting a business",
  },
];

export const tireCenterMultiSection = {
  featureBlocks: [
    {
      blockTitle: "Enable appointment booking 24/7",
      blockSubTitle:
        "Let the tech do the work when it comes to managing appointments and team member schedules. Our online booking solution is all about making life easy for you and your customers. And helping your sales soar by as much as 40%. ",
      blockList: [
        "Online appointment booking—any time, any day",
        "Interactive calendar for drop-offs & appointments",
        "Text and email reminders to reduce no-shows",
        "Employee shift management",
        "Mailing list builder",
      ],
      blockImg: "tc-b.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/tire-center/demo",
      },
    },
    {
      blockTitle: "Cut the wires. Connect with customers. Get paid",
      blockSubTitle:
        "SpotOn Terminal makes it easy to take payments wherever your customers are—in the lobby, in the shop, or curbside. And with full Marketing and Loyalty integration, your customers can redeem digital coupons and loyalty rewards during checkout with ease—leading to increased sales for your tire shop.",
      blockList: [
        "Apple Pay, Google Pay, and NFC enabled",
        "Wi-Fi and 4G connectivity",
        "Built-in receipt printer and scanner",
        "Customer email capture to grow your mailing list",
        "Cash discount and surcharge ready",
      ],
      blockImg: "tc-c.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/tire-center/demo",
      },
    },
    {
      blockTitle: "Stand out from the competition with a slick new website",
      blockSubTitle:
        "Attract more customers with a professional website that will help your tire center show up higher in local searches. Our specialists will build it for you at a do-it-yourself price.",
      blockList: [
        "Custom website design",
        "Web hosting",
        "Custom website name",
        "Online appointment booking",
        "Search engine optimization",
      ],
      blockImg: "tc-d.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/tire-center/demo",
      },
    },
    {
      blockTitle: "Market your business like a pro",
      blockSubTitle:
        "Send limited-time deals, promote new products, and send seasonal reminders with SpotOn Marketing. You’ll be able to send branded emails, update your social media, and create campaigns like never before.",
      blockList: [
        "Prompt new visits and drive traffic to your tire shop",
        "Track campaign performance with real-time analytics",
        "Connect with customers everywhere using Facebook, email, or push notifications",
      ],
      blockImg: "tc-e.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/tire-center/demo",
      },
    },
    {
      blockTitle: "Boost repeat business with loyalty rewards",
      blockSubTitle:
        "Increase revenue, build your customer base, and encourage repeat visits with personalized rewards using SpotOn Loyalty. It’s easy to set up, easy to maintain, and it gives customers the incentive they need to come back for recurring services like tire rotations, balancing, and the next time they need tires or rims.",
      blockList: [
        "Create personalized rewards",
        "Enroll customers during checkout",
        "Increase sales with each check-in & redemption",
      ],
      blockImg: "tc-f.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/automotive/tire-center/demo",
      },
    },
  ],
};

export const tireCenterCTA = {
  title: "Book your consultation.",
  ctaImg: "auto-repair-cta.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/automotive/tire-center/demo",
  },
};

export const tireCenterVideo = {
  title: "The road to success",
  videoBtn: "Watch video",
  posterImg: "oil-thumb.png",
  posterWrapperClass: "bg-blue",
  videoUrlId: "4pIhYB_LvJM",
};

export const tireCenterTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const tireCenterArticles = {
  title: "Expert articles & advice on growing your tire center",
  bgText: "Articles.",
};

export const tireCenterFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/page-2",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const tireCenterCar = {
  title: "Run a different type of automotive business",
  subtext: "Tire Centers are just one of our specialties.",
  punctuation: "?",
  slides: [
    {
      bg: "auto-repair.png",
      cardTitle: "Auto repair",
      cardContent: "Drive repeat visits and more",
      icon: "auto-repair.png",
      linkTo: "/automotive/auto-repair",
    },
    {
      bg: "auto-parts.png",
      cardTitle: "Auto parts",
      cardContent: "Tools to grow your business\n",
      icon: "auto-parts.png",
      linkTo: "/automotive/auto-parts",
    },
    {
      bg: "car-sales.png",
      cardTitle: "Auto sales",
      cardContent: "Tools to grow your business\n",
      icon: "auto-sales.png",
      linkTo: "/automotive/auto-sales",
    },
    {
      bg: "oil-change.png",
      cardTitle: "Oil Change Shop",
      cardContent:
        "Find more customers, book more appointments, keep them coming back",
      icon: "oil-change.png",
      linkTo: "/automotive/oil-change-shop",
    },
  ],
};
